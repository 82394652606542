/* tslint:disable */
/* eslint-disable */
/**
 * Dataland QA Service API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/qaservice/model';
// @ts-ignore
import type { QaStatus } from '../../../../../../org/dataland/datalandfrontend/openApiClient/qaservice/model';
// @ts-ignore
import type { ReviewInformationResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/qaservice/model';
/**
 * QaControllerApi - axios parameter creator
 * @export
 */
export const QaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Set the quality status after a dataset has been reviewed
         * @summary Assign a quality status to a unreviewed dataset
         * @param {string} dataId 
         * @param {QaStatus} qaStatus 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignQaStatus: async (dataId: string, qaStatus: QaStatus, message?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('assignQaStatus', 'dataId', dataId)
            // verify required parameter 'qaStatus' is not null or undefined
            assertParamExists('assignQaStatus', 'qaStatus', qaStatus)
            const localVarPath = `/qa/datasets/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (qaStatus !== undefined) {
                localVarQueryParameter['qaStatus'] = qaStatus;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the QA review status of uploaded dataset for a given id.Users can get the review status of their own datasets.Admins and reviewer can get the review status for all datasets.
         * @summary Gets the QA review status of an uploaded dataset for a given id.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetById: async (dataId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('getDatasetById', 'dataId', dataId)
            const localVarPath = `/qa/datasets/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a ordered list of metadata sets which need to be reviewed
         * @summary Get unreviewed metadata sets of qa reports.
         * @param {Set<GetUnreviewedMetadataSetsDataTypeEnum>} [dataType] 
         * @param {Set<string>} [reportingPeriod] 
         * @param {string} [companyName] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreviewedMetadataSets: async (dataType?: Set<GetUnreviewedMetadataSetsDataTypeEnum>, reportingPeriod?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/qa/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (dataType) {
                localVarQueryParameter['dataType'] = Array.from(dataType);
            }

            if (reportingPeriod) {
                localVarQueryParameter['reportingPeriod'] = Array.from(reportingPeriod);
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (chunkSize !== undefined) {
                localVarQueryParameter['chunkSize'] = chunkSize;
            }

            if (chunkIndex !== undefined) {
                localVarQueryParameter['chunkIndex'] = chunkIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QaControllerApi - functional programming interface
 * @export
 */
export const QaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Set the quality status after a dataset has been reviewed
         * @summary Assign a quality status to a unreviewed dataset
         * @param {string} dataId 
         * @param {QaStatus} qaStatus 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignQaStatus(dataId: string, qaStatus: QaStatus, message?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignQaStatus(dataId, qaStatus, message, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.assignQaStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the QA review status of uploaded dataset for a given id.Users can get the review status of their own datasets.Admins and reviewer can get the review status for all datasets.
         * @summary Gets the QA review status of an uploaded dataset for a given id.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetById(dataId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetById(dataId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.getDatasetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a ordered list of metadata sets which need to be reviewed
         * @summary Get unreviewed metadata sets of qa reports.
         * @param {Set<GetUnreviewedMetadataSetsDataTypeEnum>} [dataType] 
         * @param {Set<string>} [reportingPeriod] 
         * @param {string} [companyName] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreviewedMetadataSets(dataType?: Set<GetUnreviewedMetadataSetsDataTypeEnum>, reportingPeriod?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewInformationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreviewedMetadataSets(dataType, reportingPeriod, companyName, chunkSize, chunkIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.getUnreviewedMetadataSets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QaControllerApi - factory interface
 * @export
 */
export const QaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QaControllerApiFp(configuration)
    return {
        /**
         * Set the quality status after a dataset has been reviewed
         * @summary Assign a quality status to a unreviewed dataset
         * @param {string} dataId 
         * @param {QaStatus} qaStatus 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignQaStatus(dataId: string, qaStatus: QaStatus, message?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignQaStatus(dataId, qaStatus, message, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the QA review status of uploaded dataset for a given id.Users can get the review status of their own datasets.Admins and reviewer can get the review status for all datasets.
         * @summary Gets the QA review status of an uploaded dataset for a given id.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetById(dataId: string, options?: any): AxiosPromise<ReviewInformationResponse> {
            return localVarFp.getDatasetById(dataId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a ordered list of metadata sets which need to be reviewed
         * @summary Get unreviewed metadata sets of qa reports.
         * @param {Set<GetUnreviewedMetadataSetsDataTypeEnum>} [dataType] 
         * @param {Set<string>} [reportingPeriod] 
         * @param {string} [companyName] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreviewedMetadataSets(dataType?: Set<GetUnreviewedMetadataSetsDataTypeEnum>, reportingPeriod?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: any): AxiosPromise<Array<ReviewInformationResponse>> {
            return localVarFp.getUnreviewedMetadataSets(dataType, reportingPeriod, companyName, chunkSize, chunkIndex, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QaControllerApi - interface
 * @export
 * @interface QaControllerApi
 */
export interface QaControllerApiInterface {
    /**
     * Set the quality status after a dataset has been reviewed
     * @summary Assign a quality status to a unreviewed dataset
     * @param {string} dataId 
     * @param {QaStatus} qaStatus 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    assignQaStatus(dataId: string, qaStatus: QaStatus, message?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the QA review status of uploaded dataset for a given id.Users can get the review status of their own datasets.Admins and reviewer can get the review status for all datasets.
     * @summary Gets the QA review status of an uploaded dataset for a given id.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    getDatasetById(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<ReviewInformationResponse>;

    /**
     * Gets a ordered list of metadata sets which need to be reviewed
     * @summary Get unreviewed metadata sets of qa reports.
     * @param {Set<GetUnreviewedMetadataSetsDataTypeEnum>} [dataType] 
     * @param {Set<string>} [reportingPeriod] 
     * @param {string} [companyName] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    getUnreviewedMetadataSets(dataType?: Set<GetUnreviewedMetadataSetsDataTypeEnum>, reportingPeriod?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ReviewInformationResponse>>;

}

/**
 * QaControllerApi - object-oriented interface
 * @export
 * @class QaControllerApi
 * @extends {BaseAPI}
 */
export class QaControllerApi extends BaseAPI implements QaControllerApiInterface {
    /**
     * Set the quality status after a dataset has been reviewed
     * @summary Assign a quality status to a unreviewed dataset
     * @param {string} dataId 
     * @param {QaStatus} qaStatus 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public assignQaStatus(dataId: string, qaStatus: QaStatus, message?: string, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).assignQaStatus(dataId, qaStatus, message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the QA review status of uploaded dataset for a given id.Users can get the review status of their own datasets.Admins and reviewer can get the review status for all datasets.
     * @summary Gets the QA review status of an uploaded dataset for a given id.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public getDatasetById(dataId: string, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).getDatasetById(dataId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a ordered list of metadata sets which need to be reviewed
     * @summary Get unreviewed metadata sets of qa reports.
     * @param {Set<GetUnreviewedMetadataSetsDataTypeEnum>} [dataType] 
     * @param {Set<string>} [reportingPeriod] 
     * @param {string} [companyName] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public getUnreviewedMetadataSets(dataType?: Set<GetUnreviewedMetadataSetsDataTypeEnum>, reportingPeriod?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).getUnreviewedMetadataSets(dataType, reportingPeriod, companyName, chunkSize, chunkIndex, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetUnreviewedMetadataSetsDataTypeEnum = {
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    Lksg: 'lksg',
    P2p: 'p2p',
    Sfdr: 'sfdr',
    Vsme: 'vsme',
    EsgQuestionnaire: 'esg-questionnaire',
    Heimathafen: 'heimathafen',
    AdditionalCompanyInformation: 'additional-company-information'
} as const;
export type GetUnreviewedMetadataSetsDataTypeEnum = typeof GetUnreviewedMetadataSetsDataTypeEnum[keyof typeof GetUnreviewedMetadataSetsDataTypeEnum];
